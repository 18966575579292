<template>
  <div>
    <v-layout class="mb-3" wrap>
      <v-flex lg12 md12 sm12 pr-2>
        <v-card flat>
          <v-card-title>
            {{ device.name }}
            <v-spacer></v-spacer>
            <markup language="bash" :code="deviceSSHCLI" :inline="false"></markup>
            <v-spacer></v-spacer>
            <v-chip
                outlined
                v-for="(key, label) in device.labels"
                :key="key"
                color="primary"
                class="mr-1"
                >{{ label }}:{{ device.labels[label] }}</v-chip
              >
          </v-card-title>
          <v-card-text>
            <div ref="terminal" class="synpse-xterm"></div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<style >
.synpse-xterm {
  border-top: 10px solid rgb(0, 0, 0);
  border-left: 8px solid rgb(0, 0, 0);
  border-right: 8px solid rgb(0, 0, 0);
  height: auto;
}
</style>

<script>

import Markup from '../helpers/Markup';
import { Terminal } from "xterm";
import * as fit from "xterm/lib/addons/fit/fit";
import { Base64 } from "js-base64";
import * as webLinks from "xterm/lib/addons/webLinks/webLinks";
import * as search from "xterm/lib/addons/search/search";
import "xterm/dist/xterm.css"

let bindTerminalResize = (term, websocket) => {
  let onTermResize = size => {
    websocket.send(
      JSON.stringify({
        type: "resize",
        rows: size.rows,
        cols: size.cols,
      })
    )
  }
  // register resize event.
  term.on("resize", onTermResize)
  // unregister resize event when WebSocket closed.
  websocket.addEventListener("close", function () {
    term.off("resize", onTermResize)
  });
};
let bindTerminal = (term, websocket, bidirectional, bufferedTime) => {
  term.socket = websocket
  let messageBuffer = null
  let handleWebSocketMessage = function (ev) {    
    if (bufferedTime && bufferedTime > 0) {
      if (messageBuffer) {
        messageBuffer += ev.data
      } else {
        messageBuffer = ev.data
        setTimeout(function () {
          term.write(messageBuffer)
        }, bufferedTime)
      }
    } else {
      term.write(ev.data)
    }
  };
  let handleTerminalData = function (data) {
    websocket.send(
      JSON.stringify({
        type: "cmd",
        cmd: Base64.encode(data) // encode data as base64 format
      })
    );
  };
  websocket.onmessage = handleWebSocketMessage;
  if (bidirectional) {
    term.on("data", handleTerminalData);
  }
  // Send heartbeat package to avoid closing WebSocket 
  // connection in GCP/AWS
  let heartBeatTimer = setInterval(function () {
    websocket.send(JSON.stringify({ type: "heartbeat", data: "" }))
  }, 20 * 1000);
  websocket.addEventListener("close", function () {
    websocket.removeEventListener("message", handleWebSocketMessage)
    term.off("data", handleTerminalData)
    delete term.socket
    clearInterval(heartBeatTimer)
  });
};
export default {
  components: {
    Markup
  },
  data() {
    return {
      ws: null,
      term: null,
    }
  },
  computed: {
    device() {
      return this.$store.state.device.device
    },
    deviceId() {
      return this.$route.params.device
    },
    projectId() {
      return this.$route.params.project
    },
    wsBase() {
      return `${window.location.protocol === "https:" ? "wss://" : "ws://"}${window.location.host}`;
    },
    wsUrl() {
      let token = this.$store.getters.jwt
      return `${this.wsBase}/api/projects/${this.projectId}/devices/${this.deviceId}/ssh?cols=${this.term.cols}&rows=${this.term.rows}&_t=${token}`
    },
    deviceSSHCLI() {
      return `synpse ssh ${this.deviceId}`
    }
  },
  mounted() {
    let q = {
      projectId: this.projectId,
      deviceId: this.deviceId
    }
    this.$store.dispatch('GetDevice', q)  
    // Initiate SSH connection
    this.doOpened()
  },
  beforeDestroy() {
    // Terminate connection
    this.doClose()
  },
  methods: {
    onWindowResize() {
      this.term.fit(); // it will make terminal resized.
    },
    doLink(ev, url) {
      if (ev.type === 'click') {
        window.open(url)
      }
    },
    doClose() {
      window.removeEventListener("resize", this.onWindowResize)
      if (this.ws) {
        this.ws.close()
      }
      if (this.term) {
        this.term.dispose()
      }
    },
    doOpened() {
      Terminal.applyAddon(fit)
      Terminal.applyAddon(webLinks)
      Terminal.applyAddon(search)

      this.term = new Terminal({
        fontSize: 18,
        cursorBlink: true,
        cursorStyle: 'bar',
        bellStyle: "sound",
      })
      this.term.open(this.$refs.terminal)
      this.term.fit() // first resizing

      this.term.webLinksInit(this.doLink)
      // term.on("resize", this.onTerminalResize);
      window.addEventListener("resize", this.onWindowResize)
      this.ws = new WebSocket(this.wsUrl)
      // this.ws.onerror = event => {
      //     console.log('error')
      //     console.log(event)     
      // };
      this.ws.onclose = (ce) => {        
        this.term.setOption("cursorBlink", false);
        if (ce.code !== 1005) {
          this.$store.dispatch('Notify', `Error code'${ce.code}': ${ce.reason}`)
          // this.$notify.error({
          //   title: `code ${ce.code}`,
          //   message: ce.reason,
          // });
          this.doClose()
        }
      };
      bindTerminal(this.term, this.ws, true, -1)
      bindTerminalResize(this.term, this.ws)
    },
  },
}
</script>